<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner title="RETAILER PROFILE" :breadcrumb="[{ label: 'Retailer Profile' }]">
        </banner>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding: 5px 5px 5px 5px">
            <div class="card custom-card" style="height: 100%">
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Retailer Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Retailer Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img style="width: 40%; border-radius: 15px" :src="photo" alt="" />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="retailer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: #f21300;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="retailer">{{ retailer.code }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="retailer">{{ retailer.name }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="retailer">{{ dateTime(retailer.dob) }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone : </b>
                      <span v-if="retailer">{{ retailer.phone }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>E-mail : </b>
                      <span v-if="retailer">{{ retailer.email }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Address : </b>
                      <span v-if="retailer">{{ retailer.address }} {{ retailer.city }} {{ retailer.pin }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="retailer.state">{{ retailer.state.name }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Pan Number : </b>
                      <span v-if="retailer.retailer_kyc">{{
                        retailer.retailer_kyc.panNo
                      }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhar Number : </b>
                      <span v-if="retailer.retailer_kyc">{{
                        retailer.retailer_kyc.aadharNo
                      }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="photo"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <!-- v-if="retailer.status == 'true'" -->
                      <p
                        v-if="retailer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: #f21300;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                    <!-- <button type="button" class="btn btns text-white btn-sm"><b>Edit Profile &nbsp;
                        <font-awesome-icon icon="edit" />
                      </b></button> -->
                  </div>
                </div>
                <hr />
                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address : </b>
                      <span v-if="retailer"> {{ retailer.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login : </b>
                      <span v-if="retailer"> {{ dateTimeWithTime(retailer.last_login) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-md-7 col-sm-7" style="padding: 5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card" style="height: 100%">
              <div class="card-body">
                <div v-if="'false' == portal">
                  <!-- <div class="row" v-if="!wallet.id">
                    <div class="col-xl-12">
                      <button
                        type="button"
                        class="btn btn-sm text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#createwallet"
                        style="background-color: #f21300"
                      >
                        Create Wallet
                      </button>
                    </div>
                  </div> -->
                  <div class="row" v-if="wallet.id">
                    <div class="col-12">
                      <h4 class="d-xl-none d-md-none d-block text-center">
                        <b style="color: #00364f; font-weight: bolder">Wallet</b>
                      </h4>
                      <h4 class="d-none d-xl-block d-md-block text-start">
                        <b style="color: #00364f; font-weight: bolder">Wallet</b>
                      </h4>
                    </div>
                    <!-- Qr Code responsive Mobile screen  -->
                    <div
                      class="d-xl-none d-md-none d-lg-none d-block col-sm-12 col-12 text-center"
                    >
                      <!-- <img style="width:40%;" :src="wallet.qr_path" alt=""> -->
                      <img style="width: 40%" :src="wallet.qr_path" alt="" /><br />
                      <p
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 32%;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                          margin-left: 8px;
                        "
                      >
                        <b> Active</b>
                      </p>
                    </div>

                    <!-- end Qr Code responsive Mobile screen  -->
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                      <div class="text">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                        <b>Name : </b>
                        <span v-if="wallet">{{ wallet.beneficiary_name }}</span>
                      </div>
                      <div class="text">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                        <b>Acount Number : </b>
                        <span v-if="wallet">{{ wallet.beneficiary_account_number }}</span>
                      </div>
                      <div class="text">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                        <b>IFSC : </b>
                        <span v-if="wallet">{{ wallet.ifsc_code }}</span>
                      </div>
                      <div class="text">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                        <b>Wallet Ballance : </b>
                        <span v-if="wallet">{{ wallet.balanceAmount }}</span>
                      </div>
                      <div class="text">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                        <b>Virtual Account : </b>
                        <span v-if="wallet">{{ wallet.merchant_vpa }}</span>
                      </div>
                    </div>

                    <!-- Qr Code responsive Pc screen  -->
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 d-none d-xl-block d-lg-block d-md-block"
                    >
                      <!-- <img style="width:100%;" :src="wallet.qr_path" alt="qr code"> -->
                      <img style="width: 100%" :src="wallet.qr_path" alt="" />
                      <p
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                          margin-left: 8px;
                        "
                      >
                        <b> Active</b>
                      </p>
                    </div>

                    <!-- end Qr Code responsive Pc screen  -->
                  </div>

                  <!-- start list area  -->
                  <div class="row mt-3" v-if="wallet.id">
                    <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                      <h4>
                        <b style="color: #00364f; font-weight: bolder"
                          >Beneficiary Account</b
                        >
                      </h4>
                    </div>
                    <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end">
                      <!-- <h4> <b style="color: #00364f;font-weight: bolder;"><button type="button"
                          class="btn text-white btn-sm"
                          style="background-color:#f21300;font-size:13px;letter-spacing: 1px;" data-bs-toggle="modal"
                          data-bs-target="#createbeneficiary"><b>
                            <font-awesome-icon icon="plus" /> Create
                          </b></button></b> </h4> -->
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                            <!-- <th></th> -->
                            <th><b>+</b></th>
                            <th>Name</th>

                            <th>Bank</th>
                            <th>Ifsc</th>
                            <th>Ac No.</th>
                            <th>Status</th>
                            <!-- <th>Action</th> -->
                          </tr>

                          <tbody>
                            <tr
                              v-for="(acount, index) in beneficiaryaccounts"
                              :key="index"
                            >
                              <td>
                                <img
                                  style="width: 8px; margin-left: 8px"
                                  src="/assets/image/record.png"
                                  alt=""
                                />
                              </td>
                              <td>{{ acount.account_holder_name }}</td>
                              <td>{{ acount.bank_name }}</td>
                              <td>{{ acount.ifsc_code }}</td>
                              <td>{{ acount.account_number }}</td>

                              <td>
                                <p
                                  v-if="acount.status == 'true'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Active</b>
                                </p>
                                <p
                                  v-else
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: red;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> InActive</b>
                                </p>
                              </td>
                              <!-- <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">

                                <button type="button" data-bs-toggle="modal" data-bs-target="#createbeneficiary"
                                  @click="editBeneficiaryAcount(acount, 'Update')" class="btn btns btn-sm btn-success"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="edit" />
                                </button>



                              </div>
                            </td> -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr style="color: #e3e3e3" class="mt-2" />
                    <div class="row">
                      <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                        <h4>
                          <b style="color: #00364f; font-weight: bolder">Withdrawls</b>
                        </h4>
                      </div>
                    </div>
                    <hr style="color: #e3e3e3" />
                  </div>
                  <!-- end list area -->
                </div>

                <div v-if="retailer.retailer_package">
                  <h4 style="color: #00364f"><strong>Package</strong></h4>
                  <div class="container-fluid table-scroll" style="margin-left: -5px">
                    <table class="table table-hover table-sm text font">
                      <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                        <th class="text-truncate">Date</th>
                        <th class="text-truncate">Package</th>
                        <th class="text-truncate"> Subscription Amount</th>

                        <th class="text-truncate">Status</th>
                      </tr>
                      <tbody class="text-truncate">
                        <tr
                          v-for="(pkg, index) in retailer.retailer_package"
                          :key="index"
                        >
                          <td>
                            <span>{{ dateTime(pkg.subscriptionDate) }}</span>
                          </td>
                          <td>
                            <span v-if="pkg.package">{{ pkg.package.package }}</span>
                          </td>
                          <td>
                            <!-- <span v-if="client">{{ dateTime(client.dob) }}</span> -->
                            <span v-if="pkg">{{ pkg.subscriptionAmt }}</span>
                          </td>

                          <td class="text-truncate">
                            <span v-if="pkg.package">



                              <p
                              v-if="pkg.package.status == 'true'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: green;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>Active</b>
                            </p>
                            <p
                              v-else
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: red;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>InActive</b>
                            </p>
                            </span>
                           
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm">
              <tr
                class="table-wrapper-scroll-y text-white rounded-circle"
                style="background-color: #00364f; height: 40px"
              >
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="adharFrontImg"
                      alt=""
                    />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <!-- <a
                        :href="adharFrontImg"
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                        download
                        target="_blank"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> -->
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="adharBackImg"
                      alt=""
                    />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="panImg"
                      alt=""
                    />
                  </td>

                  <td>
                    <p>Pan</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPanPhoto.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- wallet retailer -->
</template>

//
<script>
import moment from "moment";
import Banner from "../../../components/admin/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "RetailerProfile",

  components: {
    Banner,
  },
  data() {
    return {
      portal: "",
      photo: this.$store.state.placeholderImg,
      staff: {},
      state: {},
      staffkyc: [],
      beneficiaryaccounts: [],
      extensionkycAdharFront:'',
      extensionkycPanPhoto:'',
      extensionkycAdharBack:'',
      msg: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImgPdf: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      passBookImgUrl: "",
      retailer: {},
      wallet: {},
    };
  },
  methods: {
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    loadBeneficiaryAccount() {
      this.$axios
        .get(`admin/beneficiaryaccount?retailer_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("_______________beneficiaryaccount______________in_________");
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },

    loadRetailers() {
      this.$axios
        .get(`admin/retailer/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("______________retailer______________________");
          this.retailer = res.data.data;

          console.log(this.retailer);
          if (this.retailer.retailer_wallet) {
            this.wallet = this.retailer.retailer_wallet;
          }

          if (this.retailer.photo != null) {
            this.photo =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/thumbs/" +
              this.retailer.photo;
          } else {
            this.photo = this.$store.state.placeholderImg;
          }

          if (this.retailer.retailer_kyc.aadharBackPhoto != null) {
           
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/thumbs/" +
              this.retailer.retailer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/thumbs/" +
                this.retailer.retailer_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/" +
              this.retailer.retailer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/" +
                this.retailer.retailer_kyc.aadharBackPhoto;
            }
            

            this.adharBackImgPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.retailer.retailer_kyc.aadharFrontPhoto != null) {
         
            // this.adharFrontImg = this.$store.state.imgUrl + '/retailer/' + this.retailer.id + '/thumbs/' + this.retailer.retailer_kyc.aadharFrontPhoto
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/thumbs/" +
              this.retailer.retailer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/thumbs/" +
                this.retailer.retailer_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/" +
              this.retailer.retailer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/" +
                this.retailer.retailer_kyc.aadharFrontPhoto;
            }
           
            this.adharFrontImgPdf = this.adharFrontImg;
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.retailer.retailer_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/thumbs/" +
              this.retailer.retailer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/thumbs/" +
                this.retailer.retailer_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer.id +
              "/" +
              this.retailer.retailer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/retailer/" +
                this.retailer.id +
                "/" +
                this.retailer.retailer_kyc.panPhoto;
            }
           

            this.panImgPdf = this.panImg;

            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.adminPageTitles.retailerView
    console.log(this.$store.state.adminPageTitles.retailerView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      // this.loadWallet();
      this.loadRetailers();
      this.loadPageTitle()
      this.portal = localStorage.getItem("portal");

      // this.loadStates();
      // this.loadBeneficiaryAccount();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #e70c0c;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
